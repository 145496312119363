var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    [
      _c(
        "v-toolbar",
        { attrs: { color: "primary" } },
        [
          _c("v-toolbar-title", { staticClass: "white--text" }, [
            _vm._v("Add iPhone/iPad Device")
          ]),
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { icon: "", dark: "" },
              on: {
                click: function($event) {
                  return _vm.close(false)
                }
              }
            },
            [_c("v-icon", [_vm._v("close")])],
            1
          )
        ],
        1
      ),
      _vm.currentStep === _vm.steps.input
        ? [
            _c(
              "v-card-text",
              { staticClass: "pt-2" },
              [
                _c("h3", [
                  _vm._v(
                    "On iPhone/iPad we will track the domain (website) visited and optionally filter."
                  )
                ]),
                _c("div", { staticClass: "caption" }, [
                  _vm._v(
                    "Due to technical limitations it's not possible to build a good solution that performs device wide screenshot accountability for iPhone/iPad."
                  )
                ]),
                _c("v-text-field", {
                  staticClass: "mt-2",
                  attrs: {
                    color: "primary",
                    label: "Device Name",
                    hint: "What should this device be named?",
                    "persistent-hint": true
                  },
                  model: {
                    value: _vm.device.name,
                    callback: function($$v) {
                      _vm.$set(_vm.device, "name", $$v)
                    },
                    expression: "device.name"
                  }
                }),
                _c(
                  "v-radio-group",
                  {
                    attrs: {
                      color: "primary",
                      label: "Choose a filter",
                      column: ""
                    },
                    model: {
                      value: _vm.device.filterId,
                      callback: function($$v) {
                        _vm.$set(_vm.device, "filterId", $$v)
                      },
                      expression: "device.filterId"
                    }
                  },
                  [
                    _vm._l(_vm.filters, function(filter) {
                      return [
                        _c(
                          "div",
                          { key: filter.id },
                          [
                            _c("v-radio", {
                              key: filter.id,
                              staticClass: "mb-0",
                              attrs: { label: filter.name, value: filter.id }
                            }),
                            _c("div", { staticClass: "caption pl-8 mb-2" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(filter.description) +
                                  "\n                "
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    })
                  ],
                  2
                )
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      disabled:
                        !_vm.device.name || !_vm.device.filterId || _vm.loading,
                      loading: _vm.loading
                    },
                    on: { click: _vm.addDohDevice }
                  },
                  [_vm._v("\n            Next\n          ")]
                )
              ],
              1
            )
          ]
        : _vm.currentStep === _vm.steps.setup
        ? [
            _c(
              "v-card-text",
              { staticClass: "pt-2" },
              [
                _c("d-n-s-cloak-install-instructions", {
                  attrs: {
                    "filter-id-prop": this.device.filterId,
                    "device-id-prop": this.device.deviceId,
                    "device-name-prop": this.device.name
                  }
                })
              ],
              1
            ),
            _c("v-divider"),
            _c(
              "v-card-actions",
              [
                _c("v-spacer"),
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.close(true)
                      }
                    }
                  },
                  [_vm._v("\n            Done\n          ")]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }