<template>
  <div>
    <v-dialog v-model="showDialog" scrollable :fullscreen="$store.state.isMobile" max-width="600px">
      <v-card>
        <v-card-title class="primary">
          <span class="white--text title">Add Device</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text class="pa-2">

          <v-alert :value="$store.getters.insufficientSubscription" v-if="$store.getters.insufficientSubscription">
            <v-icon color="accent">warning</v-icon>
            You must upgrade your subscription or delete devices to add more <b>monitored</b> devices!<br />
            <v-btn color="accent" @click="$router.push('account')" block>Upgrade now!</v-btn>
          </v-alert>

          <!--          icons from https://hotpot.ai/free_icons-->

          <v-row align="center" justify="center" no-gutters class="mb-2">
            <v-col :cols="12" class="pt-1 headline">
              {{ `Download ${appName}` }}
            </v-col>
          </v-row>

          <v-row style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/android/android-setup-guide`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_android.png" width="56px" height="56px" class="mr-3" style="float: right;" />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">Android</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/android/android-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20Android&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Fandroid%2Fandroid-setup-guide%20for%20installing%20${appName}%20on%20Android.`" target="_blank">
                Email Instructions
              </a>
            </v-col>
          </v-row>

          <v-row style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" class="mt-2" no-gutters @click="openAddDohDevice">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_ios.png" width="56px" height="56px" class="mr-3" style="float: right;" />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">iPhone / iPad</div>
              <br />
              <div class="font-italic caption error--text">Web history tracking only!  iOS does NOT support screenshots.</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/ios/iphone-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20iOS&body=Click%20the%20link%20to%20view%20the%20iOS%20setup%20instructions%20for%20${appName}%3A%20https%3A%2F%2F${supportDomain}%2Farticles%2Fios%2Fiphone-setup-guide`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row class="mt-3" style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/windows/windows-setup-guide`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_windows.png" width="56px" height="56px" class="mr-3" style="float: right;"  />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">Windows</div>
              <!--              <br />-->
              <!--              <div class="font-italic caption">Beta release.  Report issues to support@truple.io.</div>-->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/windows/windows-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20Windows&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Fwindows%2Fwindows-setup-guide%20for%20installing%20${appName}%20on%20Windows.`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row class="mt-3" style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/macos/macos-setup-guide`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_apple.png" width="56px" height="56px" class="mr-3" style="float: right;"  />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">MacOS</div>
              <br />
              <div class="font-italic caption">Beta release.  Report issues to support@truple.io.</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/macos/macos-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20MacOS&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Fmacos%2Fmacos-setup-guide%20for%20installing%20${appName}%20on%20MacOS.`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row class="mt-3" style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/linux/linux-setup-guide`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_linux.png" width="56px" height="56px" class="mr-3" style="float: right;"  />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">Linux</div>
              <br />
              <div class="font-italic caption">Alpha release.  Report issues to support@truple.io.</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/linux/linux-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20Linux&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Flinux%2Flinux-setup-guide%20for%20installing%20${appName}%20on%20Linux.`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row class="mt-3" style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/kindle/kindle-setup-guide`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_kindle.png" height="56px" class="mr-3" style="float: right;"  />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">Kindle Fire</div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/kindle/kindle-setup-guide`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20Kindle%20Fire&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Fkindle%2Fkindle-setup-guide%20for%20installing%20${appName}%20on%20Kindle%20Fire.`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row class="mt-3" style="border: 1px solid #A8A8A8; border-radius: 5px; cursor: pointer;" align="center" justify="center" no-gutters @click="open(`https://${supportDomain}/articles/chromeos/setup-chromebook`)">
            <v-col :cols="3" class="pt-1">
              <img src="/static/img/platforms/logo_chromebook.png" height="56px" class="mr-3" style="float: right;"  />
            </v-col>
            <v-col :cols="9">
              <div class="headline" style="display: inline-block;">Chromebook</div>
              <!--              <br />-->
              <!--              <div class="font-italic caption">Beta release.  Report issues to support@truple.io.</div>-->
            </v-col>
          </v-row>
          <v-row dense>
            <v-col :cols="12" class="centered">
              <a :href="`https://${supportDomain}/articles/chromeos/setup-chromebook`" target="_blank">View Instructions</a> |
              <a :href="`mailto:?subject=Setup%20${appName}%20on%20Chromebook&body=See%20https%3A%2F%2F${supportDomain}%2Farticles%2Fchromeos%2Fsetup-chromebook%20for%20installing%20${appName}%20on%20Chromebook.`" target="_blank">Email Instructions</a>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" no-gutters class="mt-3">
            <v-col :cols="12">
              <div class="centered">Contact <a href="mailto:support@truple.io">support@truple.io</a> if you need any help.</div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDohDialog" scrollable :fullscreen="$store.state.isMobile" max-width="900px">
      <add-doh-device @on-close="close" />
    </v-dialog>
  </div>
</template>

<script>
import AddDohDevice from "@/components/devices/doh/AddDohDevice";
export default {
  name: "AddDeviceDialog",
  components: {
    AddDohDevice
  },
  props: {
    value: {}
  },
  data() {
    return {
      appName: process.env.VUE_APP_NAME,
      supportDomain: process.env.VUE_APP_SUPPORT_DOMAIN,
      showDialog: this.value,
      showDohDialog: false
    }
  },
  methods: {
    close() {
      this.showDialog = false
      this.showDohDialog = false
      this.$emit('input', false)
      this.$emit('on-close')
    },
    openAddDohDevice() {
      this.showDialog = false
      this.showDohDialog = true
    },
    open(url) {
      window.open(url, '_blank')
    }
  },
  watch: {
    value: function(newVal) {
      this.showDialog = newVal
    }
  }
}
</script>

<style scoped>

</style>