var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            fullscreen: _vm.$store.state.isMobile,
            "max-width": "600px"
          },
          model: {
            value: _vm.showDialog,
            callback: function($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "primary" },
                [
                  _c("span", { staticClass: "white--text title" }, [
                    _vm._v("Add Device")
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    { attrs: { icon: "", dark: "" }, on: { click: _vm.close } },
                    [_c("v-icon", [_vm._v("close")])],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-2" },
                [
                  _vm.$store.getters.insufficientSubscription
                    ? _c(
                        "v-alert",
                        {
                          attrs: {
                            value: _vm.$store.getters.insufficientSubscription
                          }
                        },
                        [
                          _c("v-icon", { attrs: { color: "accent" } }, [
                            _vm._v("warning")
                          ]),
                          _vm._v(
                            "\n          You must upgrade your subscription or delete devices to add more "
                          ),
                          _c("b", [_vm._v("monitored")]),
                          _vm._v(" devices!"),
                          _c("br"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "accent", block: "" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.push("account")
                                }
                              }
                            },
                            [_vm._v("Upgrade now!")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-row",
                    {
                      staticClass: "mb-2",
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      }
                    },
                    [
                      _c(
                        "v-col",
                        { staticClass: "pt-1 headline", attrs: { cols: 12 } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s("Download " + _vm.appName) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/android/android-setup-guide"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_android.png",
                            width: "56px",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Android")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/android/android-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20Android&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fandroid%2Fandroid-setup-guide%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20Android.",
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n              Email Instructions\n            "
                              )
                            ]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-2",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: { click: _vm.openAddDohDevice }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_ios.png",
                            width: "56px",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("iPhone / iPad")]
                        ),
                        _c("br"),
                        _c(
                          "div",
                          { staticClass: "font-italic caption error--text" },
                          [
                            _vm._v(
                              "Web history tracking only!  iOS does NOT support screenshots."
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/ios/iphone-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20iOS&body=Click%20the%20link%20to%20view%20the%20iOS%20setup%20instructions%20for%20" +
                                  _vm.appName +
                                  "%3A%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fios%2Fiphone-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/windows/windows-setup-guide"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_windows.png",
                            width: "56px",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Windows")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/windows/windows-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20Windows&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fwindows%2Fwindows-setup-guide%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20Windows.",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/macos/macos-setup-guide"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_apple.png",
                            width: "56px",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("MacOS")]
                        ),
                        _c("br"),
                        _c("div", { staticClass: "font-italic caption" }, [
                          _vm._v(
                            "Beta release.  Report issues to support@truple.io."
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/macos/macos-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20MacOS&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fmacos%2Fmacos-setup-guide%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20MacOS.",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/linux/linux-setup-guide"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_linux.png",
                            width: "56px",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Linux")]
                        ),
                        _c("br"),
                        _c("div", { staticClass: "font-italic caption" }, [
                          _vm._v(
                            "Alpha release.  Report issues to support@truple.io."
                          )
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/linux/linux-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20Linux&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Flinux%2Flinux-setup-guide%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20Linux.",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/kindle/kindle-setup-guide"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_kindle.png",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Kindle Fire")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/kindle/kindle-setup-guide",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20Kindle%20Fire&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fkindle%2Fkindle-setup-guide%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20Kindle%20Fire.",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      staticStyle: {
                        border: "1px solid #A8A8A8",
                        "border-radius": "5px",
                        cursor: "pointer"
                      },
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.open(
                            "https://" +
                              _vm.supportDomain +
                              "/articles/chromeos/setup-chromebook"
                          )
                        }
                      }
                    },
                    [
                      _c("v-col", { staticClass: "pt-1", attrs: { cols: 3 } }, [
                        _c("img", {
                          staticClass: "mr-3",
                          staticStyle: { float: "right" },
                          attrs: {
                            src: "/static/img/platforms/logo_chromebook.png",
                            height: "56px"
                          }
                        })
                      ]),
                      _c("v-col", { attrs: { cols: 9 } }, [
                        _c(
                          "div",
                          {
                            staticClass: "headline",
                            staticStyle: { display: "inline-block" }
                          },
                          [_vm._v("Chromebook")]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { staticClass: "centered", attrs: { cols: 12 } },
                        [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "https://" +
                                  _vm.supportDomain +
                                  "/articles/chromeos/setup-chromebook",
                                target: "_blank"
                              }
                            },
                            [_vm._v("View Instructions")]
                          ),
                          _vm._v(" |\n            "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "mailto:?subject=Setup%20" +
                                  _vm.appName +
                                  "%20on%20Chromebook&body=See%20https%3A%2F%2F" +
                                  _vm.supportDomain +
                                  "%2Farticles%2Fchromeos%2Fsetup-chromebook%20for%20installing%20" +
                                  _vm.appName +
                                  "%20on%20Chromebook.",
                                target: "_blank"
                              }
                            },
                            [_vm._v("Email Instructions")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-3",
                      attrs: {
                        align: "center",
                        justify: "center",
                        "no-gutters": ""
                      }
                    },
                    [
                      _c("v-col", { attrs: { cols: 12 } }, [
                        _c("div", { staticClass: "centered" }, [
                          _vm._v("Contact "),
                          _c(
                            "a",
                            { attrs: { href: "mailto:support@truple.io" } },
                            [_vm._v("support@truple.io")]
                          ),
                          _vm._v(" if you need any help.")
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            scrollable: "",
            fullscreen: _vm.$store.state.isMobile,
            "max-width": "900px"
          },
          model: {
            value: _vm.showDohDialog,
            callback: function($$v) {
              _vm.showDohDialog = $$v
            },
            expression: "showDohDialog"
          }
        },
        [_c("add-doh-device", { on: { "on-close": _vm.close } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }